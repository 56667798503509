<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="permissions"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="permissionsPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Permissions</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('permission_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Permission
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedPermission.name"
                              :rules="nameRules"
                              label="Permission name"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col class="mt-n10" cols="12">
                            <v-checkbox
                              v-model="editedPermission.is_super_admin"
                              label="Is Super Admin"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this
                    permission?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="btn btn-primary"
                      @click="deletePermissionConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- is_super_admin -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.is_super_admin="{ item }">
            <v-chip
              v-if="item.is_super_admin"
              class="ma-2"
              color="green"
              text-color="white"
            >
              Yes
            </v-chip>

            <v-chip v-else class="ma-2" color="red" text-color="white">
              No
            </v-chip>
          </template>

          <!-- name -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.name="{ item }">
            <span class="text-capitalize">
              {{ item.name.replaceAll("_", " ") }}
            </span>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('permission_update')"
              small
              class="mr-2"
              @click="editPermission(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('permission_delete')"
              small
              @click="deletePermission(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Permissions per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementPermissions",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Is Super Admin",
          value: "is_super_admin",
        },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      permissions: [],
      editedIndex: -1,
      editedPermission: {
        id: 0,
        name: "",
        is_super_admin: false,
        created_at: "",
        updated_at: "",
      },
      defaultPermission: {
        id: 0,
        name: "",
        is_super_admin: false,
        created_at: "",
        updated_at: "",
      },

      nameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-_']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      page: 1,
      permissionsPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1 ? "New Permission" : "Edit Permission";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedPermissions();
    },

    // permissionsPerPage
    permissionsPerPage() {
      this.getApiPagedPermissions();
    },

    // perPageChoice
    perPageChoice(val) {
      this.permissionsPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("permission_access")) {
      this.$router.push("/dashboard/inbox");
    }

    if (!this.$can("super_admin")) {
      this.headers = [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
      ];
    }

    // this.getApiPermissions();
    this.getApiPagedPermissions();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiPermission();
        } else {
          this.createApiPermission();
        }
      }
    },

    // editPermission
    editPermission(permission) {
      this.editedIndex = this.permissions.indexOf(permission);
      this.editedPermission = Object.assign({}, permission);
      this.dialog = true;
    },

    // deletePermission
    deletePermission(permission) {
      this.editedIndex = this.permissions.indexOf(permission);
      this.editedPermission = Object.assign({}, permission);
      this.dialogDelete = true;
    },

    // deletePermissionConfirm
    deletePermissionConfirm() {
      this.deleteApiPermission();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedPermission = Object.assign({}, this.defaultPermission);
        this.editedIndex = -1;
      });

      this.$refs.form.resetValidation();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedPermission = Object.assign({}, this.defaultPermission);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // Get Api Permissions
    async getApiPermissions() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("permissions");

      if (res.status == 200) {
        this.permissions = res.data;
      }

      this.overlay = false;
    },

    // Get Api Paged Permissions
    async getApiPagedPermissions() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `permissions/paged?page_no=${this.page}&page_size=${this.permissionsPerPage}`
      );

      if (res.status == 200) {
        this.permissions = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // Create Api Permission
    async createApiPermission() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        "permissions",
        {
          name: this.editedPermission.name,
          is_super_admin: this.editedPermission.is_super_admin,
        },
        "Permission Created Successfully!"
      );

      if (res.status == 200) {
        this.permissions.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Update Api Permission
    async updateApiPermission() {
      this.overlay = true;

      const res = await this.$helpers.updateApiData(
        `permissions/${this.editedPermission.id}`,
        {
          id: this.editedPermission.id,
          name: this.editedPermission.name,
          is_super_admin: this.editedPermission.is_super_admin,
        },
        "Permission Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.permissions[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Delete Api Permission
    async deleteApiPermission() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `permissions/${this.editedPermission.id}`,
        "Permission Deleted Successfully!"
      );

      if (res.status == 200) {
        this.permissions.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
