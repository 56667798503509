<template>
  <div class="background">
    <Permissions />
  </div>
</template>

<script>
import Permissions from "@/components/elements/dashboard/permissions/Permissions";

export default {
  name: "DocumentManagementPermissions",

  components: {
    Permissions,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
